import trophyIcon from "../../images/trophy.jpg";
import {
  loginWithEmailPassword,
  logout,
  registerUser,
} from "../../firebase/auth";
import { useState } from "react";
import { getUserScore } from "../../firebase/functions";

export const Profile = (props) => {

  const { user, setUser, userPoints, setUserPoints } = props;
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [username, setUsername] = useState("");
  const [showLoginForm, setShowLoginForm] = useState(false);
  const [showRegisterForm, setShowRegisterForm] = useState(false);
  const [loginError, setLoginError] = useState('');
  const [registerError, setRegisterError] = useState('');

  const handleLoginSubmit = (e) => {
    e.preventDefault();
    const loginUser = async () => {
      try {
        const userCredential = await loginWithEmailPassword(email, password);
        const user = userCredential.user;
        if (user) {
          const score = await getUserScore(user.displayName);
          setUser(user);
          setShowLoginForm(false);
          setUserPoints(score);
        }
      }
      catch (error) {
        console.log(error);
        setLoginError(error.message);
      }
    };

    loginUser();
  };

  const handleRegisterSubmit = async (e) => {
    e.preventDefault();

    try {
      const user = await registerUser(email, password, username);
      setUser(user);
      setShowRegisterForm(false);

    } catch (error) {
      console.log(error.message);
      setRegisterError(error.message);
    }
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleUsernameChange = (e) => {
    setUsername(e.target.value);
  };

  const handleLogout = () => {
    logout();
    setShowLoginForm(false);
    setShowRegisterForm(false);
    setUserPoints(0);
  };

  return (
    <div>
      <div className="flex flex-col items-center">
        <div>
          <img className="top-graphic" src={trophyIcon} alt="Trophy icon" />
        </div>
        <h2>Profile</h2>
        <span>{user && user.displayName ? '@' + user.displayName : "Anonymous"}</span>

        {showLoginForm || showRegisterForm ? null : (
          <p className="text-center mt-[40px]">
            <span className="leading-none mb-3 inline-block" style={{ fontSize: 80 }}>
              {userPoints}
            </span>
            <span
              className="block uppercase leading-none"
              style={{ fontSize: 20 }}
            >
              points
            </span>
          </p>
        )}

        {user ? (
          <>
            <button
              onClick={handleLogout}
              className="py-2 px-10 my-5 rounded-md border border-white uppercase"
            >
              Log out
            </button>
          </>
        ) : (
          <>
            <div
              className={`flex flex-col mt-10 ${
                showLoginForm || showRegisterForm ? "hidden" : ""
              }`}
            >
              <button
                className="py-2 px-10 rounded-md border border-white uppercase"
                onClick={() => {
                  setShowLoginForm(true);
                  setShowRegisterForm(false);
                }}
              >
                Login
              </button>
              <button
                className="py-2 px-10 my-5 rounded-md border border-white uppercase"
                onClick={() => {
                  setShowRegisterForm(true);
                  setShowLoginForm(false);
                }}
              >
                Register
              </button>
            </div>
            {showLoginForm ? (
              <>
                <form onSubmit={handleLoginSubmit} className="mt-5">
                  <div className="flex flex-col mb-5">
                    <label htmlFor="email" className="mb-2">Email</label>
                    <input
                      name="email"
                      id="email"
                      value={email}
                      onChange={handleEmailChange}
                    />
                  </div>

                  <div className="flex flex-col mb-3">
                    <label htmlFor="password" className="mb-2">Password</label>
                    <input
                      name="password"
                      id="password"
                      value={password}
                      onChange={handlePasswordChange}
                      type="password"
                    />
                  </div>

                  <div className="flex">
                    <button
                      type="submit"
                      className="py-2 px-5 mt-3 mr-3 rounded-md uppercase border border-white"
                    >
                      Login Now
                    </button>
                    <button
                      className="py-2 px-5 mt-3 mr-3 rounded-md uppercase border border-white"
                      onClick={() => {
                        setShowLoginForm(false);
                      }}
                    >
                      Close
                    </button>
                  </div>

                  {loginError && <p className="text-red-500 mt-3">{loginError}</p>}
                </form>
              </>
            ) : null}

            {showRegisterForm ? (
              <>
                <form onSubmit={handleRegisterSubmit} className="mt-5">
                  <div className="flex flex-col mb-5">
                    <label htmlFor="email" className="mb-2">Email</label>
                    <input
                      name="email"
                      id="email"
                      value={email}
                      onChange={handleEmailChange}
                      type="email"
                    />
                  </div>

                  <div className="flex flex-col mb-5">
                    <label htmlFor="password" className="mb-2">Password</label>
                    <input
                      name="password"
                      id="password"
                      value={password}
                      onChange={handlePasswordChange}
                      type="password"
                    />
                  </div>

                  <div className="flex flex-col mb-3">
                    <label htmlFor="password" className="mb-2">Username</label>
                    <input
                      name="username"
                      id="username"
                      value={username}
                      onChange={handleUsernameChange}
                    />
                  </div>

                  <div className="flex">
                    <button
                      type="submit"
                      className="py-2 px-5 mt-3 mr-3 rounded-md uppercase border border-white"
                    >
                      Register Now
                    </button>
                    <button
                      className="py-2 px-5 mt-3 mr-3 rounded-md uppercase border border-white"
                      onClick={() => {
                        setShowRegisterForm(false);
                      }}
                    >
                      Close
                    </button>
                  </div>

                  {registerError && <p className="text-red-500 mt-3">{registerError}</p>}

                </form>
              </>
            ) : null}
          </>
        )}
      </div>
    </div>
  );
};
