import { query, collection, orderBy, limit, getDocs, getDoc, updateDoc, doc } from "firebase/firestore";
import { db } from "./core";

// Get leaderboard
export const getLeaderboard = async () => {
  
  const coll = collection(db, "users");
  const q = query(coll, orderBy("score", "desc"), limit(100));
  const querySnapshot = await getDocs(q);
  const documents = querySnapshot.docs.map(doc => ({
    id: doc.id,
    ...doc.data()
  }));
  return documents;
}

// Read user score
export const getUserScore = async (username) => {
  const userDoc = await getDoc(doc(db, "users", username));
  const { score } = userDoc.data();
  return score;
}

// Update user score
export const updateUserScore = async (username, newScore) => {
  const userDoc = doc(db, "users", username);
  const currentScore = await getUserScore(username);
  await updateDoc(userDoc, {
    score: currentScore + newScore,
  });
  console.log('Score updated successfully!')
}