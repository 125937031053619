import React, { useState, useEffect } from "react";
import { updateUserScore } from "../../firebase/functions";
import trophyIcon from "../../images/trophy.jpg";
import confetti from "canvas-confetti";
import { isDev } from "../../utils/utils";

export const PomodoroTimer = (props) => {
  const { user, userPoints, setUserPoints } = props;
  const [seconds, setSeconds] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [pomodoroPoints, setPomodoroPoints] = useState(0);
  const [isTimerActive, setIsTimerActive] = useState(false);
  const [isPomodoroComplete, setIsPomodoroComplete] = useState(false);

  // Set a regressive timer
  useEffect(() => {
    if (isTimerActive) {
      let interval = null;
      if (seconds > 0) {
        interval = setInterval(() => {
          setSeconds((seconds) => seconds - 1);
        }, 1000);
      } else if (seconds === 0 && minutes > 0) {
        if (isDev) {
          setMinutes(0);
          setSeconds(5);
        } else {
          setMinutes(minutes => minutes - 1);
          setSeconds(59);
        }

      } else if (seconds === 0 && minutes === 0) {
        clearInterval(interval);

        confetti({
          particleCount: 100,
          spread: 70,
          origin: { y: 0.6 }
        });

        console.log("Pomodoro complete! 🎉 (You earned", pomodoroPoints, "points!)");
        setIsPomodoroComplete(true);
        setIsTimerActive(false);

        // Update user score
        if (user) {
          console.log('if > user', user);
          updateUserScore(user.displayName, pomodoroPoints);
          setUserPoints(userPoints + pomodoroPoints);
        }
      }
      return () => clearInterval(interval);
    }
  }, [seconds, minutes]);

  const handlePomodoroClick = (time) => {
    console.log('Pomodoro started! 🏁')
    setIsTimerActive(true);
    setMinutes(time);
    setSeconds(0);
    setPomodoroPoints(time);
  };

  const handleReset = () => {
    console.log('Pomodoro reset!')
    setIsTimerActive(false);
    setIsPomodoroComplete(false);
    setMinutes(0);
    setSeconds(0);
  }

  return (
    <div>
      <div className="flex flex-col items-center">
        <div>
          <img className="top-graphic" src={trophyIcon} alt="Trophy icon" />
        </div>
        <h2>Pomodoro</h2>

        {!isTimerActive && !isPomodoroComplete && (
          <div className="flex flex-col mt-[80px]">
          <button
            className="py-2 px-10 mb-5 rounded-md border border-white uppercase"
            onClick={() => handlePomodoroClick(15)}
          >
            <span>15 Minutes</span>
            <span className="block text-red-600 mt-1 text-sm">
              (15 Points)
            </span>
          </button>

          <button
            className="py-2 px-10 mb-5 rounded-md border border-white uppercase"
            onClick={() => handlePomodoroClick(25)}
          >
            <span>25 Minutes</span>
            <span className="block text-red-600 mt-1 text-sm">
              (25 Points)
            </span>
          </button>

          <button
            className="py-2 px-10 mb-5 rounded-md border border-white uppercase"
            onClick={() => handlePomodoroClick(50)}
          >
            <span>50 Minutes</span>
            <span className="block text-red-600 mt-1 text-sm">(50 Points)</span>
          </button>
        </div>
        )}

        {isTimerActive && (
          <div>
            <span className='text-[80px]'>
              {minutes < 10 ? "0" + minutes : minutes}:
              {seconds < 10 ? "0" + seconds : seconds}
            </span>
          </div>
        )}

        {isPomodoroComplete && (
          <div className='flex flex-col text-center items-center'>
            <span className="text-[30px] mb-3">Pomodoro complete!</span>
            <span className="text-[20px] mb-3 uppercase" style={{color: '#23F492'}}>+{pomodoroPoints} points</span>
            <button className="py-2 px-10 mb-5 rounded-md border border-white"
              onClick={() => handleReset()}>Reset</button>
          </div>
        )}
      </div>
    </div>
  );
};