import loader from '../../images/loader.jpg';
import './Loader.scss';

export const Loader = () => {
  return (
    <section id="loader" className="flex flex-col justify-center h-screen">
      <img src={loader} alt="Pomodoro Champion logo" />
      <p id="loading" className="text-center">Loading...</p>
    </section>
  );
}