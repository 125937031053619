import { initializeApp, getApp } from 'firebase/app';
import { getFirestore, connectFirestoreEmulator } from 'firebase/firestore';
import { 
  getAuth,
  connectAuthEmulator
} from 'firebase/auth';
import { isDev } from '../utils/utils';

const firebaseApp = initializeApp({
  apiKey: "AIzaSyAGcmfe3cew2VEDxbo0QXP6uGQpW6HoXeg",
  authDomain: "pomodoro-champion.firebaseapp.com",
  projectId: "pomodoro-champion",
  storageBucket: "pomodoro-champion.appspot.com",
  messagingSenderId: "464653482375",
  appId: "1:464653482375:web:7990d2a10ede293c963b22",
  measurementId: "G-BQDQN1DDM4"
});

export const app = getApp();  
export const db = getFirestore(app);
export const auth = getAuth(firebaseApp);

// DEV ONLY
if (isDev) {
  connectFirestoreEmulator(db, 'localhost', 54000);
  connectAuthEmulator(auth, "http://localhost:9099", { disableWarnings: true });
}