import {
  getAuth,
  onAuthStateChanged,
  signOut,
  updateProfile,
  deleteUser,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  connectAuthEmulator,
} from "firebase/auth";
import { auth, db } from "./core";
import { doc, getDoc, setDoc } from "firebase/firestore";

// Login using email/password
export const loginWithEmailPassword = async (username, password) => {
  try {
    const userCredential = await signInWithEmailAndPassword(
      auth,
      username,
      password
    );
    return userCredential;
  } catch (error) {
    throw error;
  }
};

// Function to register user (and check for duplicate username)
export const registerUser = async (email, password, username) => {
  let userCredential;

  try {
    // Create user with email and password
    userCredential = await createUserWithEmailAndPassword(
      auth,
      email,
      password
    );
    const user = userCredential.user;

    const userDoc = await getDoc(doc(db, "users", username));

    // Check if username is available (i.e., document doesn't exist)
    if (userDoc.exists()) {
      throw new Error("Username is already taken.");
    }

    // Update the user's profile
    await updateProfile(user, {
      displayName: username,
    });

    // Add user to 'users' database and set username as doc ID
    await setDoc(doc(db, "users", username), {
      score: 0,
      uid: user.uid,
    });

    // Return user data or any relevant information
    return user;
  } catch (error) {
    console.error(`There was an error: ${error}`);
    // If Firestore document creation fails, delete the created Auth user
    if (userCredential && userCredential.user) {
      deleteUser(userCredential.user).then(() => {
        console.log("User deleted ❌");
      }).catch((error) => {
        // An error ocurred
        throw new Error("Error deleting user.");
      });
    }
    throw error; // Re-throw the error to be handled by the caller
  }
};

// Log out
export const logout = async () => {
  console.log("See you next time! 👋");
  await signOut(auth);
};
