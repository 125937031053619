import React, { useEffect, useState } from "react";
import trophyIcon from "../../images/trophy.jpg";
import { getLeaderboard } from "../../firebase/functions";
import "./Leaderboard.scss";

export const Leaderboard = () => {

  const [leaderboard, setLeaderboard] = useState([]);

  useEffect(() => {
    async function fetchData() {
      try {
        const leaderboardData = await getLeaderboard();
        setLeaderboard(leaderboardData);
      } catch (error) {
        // Handle any errors here
        console.error('Failed to fetch data:', error);
      }
    }

    // Call the async function
    fetchData();
  }, []);

  return (
    <div>
      <div className="flex flex-col items-center">
        <div>
          <img className="top-graphic" src={trophyIcon} alt="Trophy icon" />
        </div>
        <h2>Leaderboard</h2>
      </div>

      <ol id="the-list" className="list-decimal pl-[30px]">
        {leaderboard.map((user, index) => {
          return (
            <li key={user.id}>
              <div className="flex justify-between">
                <span>{user.id}</span> <span>{user.score}</span>
              </div>
          </li>
          )
        })}
      </ol>
    </div>
  );
};
