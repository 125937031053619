import { Loader } from "./components/Loader/Loader";
import { Profile } from "./components/Profile/Profile";
import { Leaderboard } from "./components/Leaderboard/Leaderboard";
import { PomodoroTimer } from "./components/PomodoroTimer/PomodoroTimer";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { useState, useEffect } from "react";
// import "react-tabs/style/react-tabs.css";
import "./App.scss";
import { auth } from "./firebase/core";
import { getUserScore } from "./firebase/functions";
import trophyIcon2 from './images/trophy2.jpg'
import pomodoro from './images/pomodoro.jpg';
import profile from './images/profile.jpg';
import { isDev } from "./utils/utils";

const App = () => {

  console.log('🍅👑 v.1.0 (beta)');

  const [user, setUser] = useState(null);
  const [userPoints, setUserPoints] = useState(0);

  const [showLoader, setShowLoader] = useState(true);

  useEffect(() => {
    // This function automatically gets executed when the component mounts
    const unsubscribe = auth.onAuthStateChanged((currentUser) => {
      if (currentUser) {
        console.log("%c User is signed in ➡",);
        // User is signed in
        setUser(currentUser);

      } else {
        // User is signed out
        console.log("%c User is signed out ⬅️");
        setUser(null);
      }
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, []);

  // Hide loader after a few seconds
  useEffect(() => {
    const timer = setTimeout(() => {
      setShowLoader(false);
    }, 3000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="app p-[15px] h-screen">
      {showLoader && !isDev && <Loader />}
      <Tabs>
        <TabPanel>
          <Leaderboard />
        </TabPanel>
        <TabPanel>
          <PomodoroTimer user={user} setUser={setUser} userPoints={userPoints} setUserPoints={setUserPoints} />
        </TabPanel>
        <TabPanel>
          <Profile user={user} setUser={setUser} userPoints={userPoints} setUserPoints={setUserPoints} />
        </TabPanel>

        <TabList className={'flex justify-center w-full fixed bottom-[10px] left-0'}>
          <Tab>
            <img src={trophyIcon2} alt="Trophy icon" />
          </Tab>
          <Tab>
            <img src={pomodoro} alt="Pomodoro icon" />
          </Tab>
          <Tab>
            <img src={profile} alt="Profile icon" />
          </Tab>
        </TabList>
      </Tabs>
    </div>
  );
};

export default App;
